import { storeToRefs } from 'pinia'
import { TEXT_CONTENT, LIST_LEVEL, LIST_LEVEL_PROESS } from '~/constants/processLevel'
import { useUserApi } from '@/api/user'
import { PATH_PROMOTION_URL } from '~/config/constant'
import { ACCOUNT_URLS } from '~/config/page-url'
import { useLoading } from '@/composables/useLoading'
import { useHeader } from '~/composables/useHeader'
import { useAccountStore } from '~/store/account'
import { useAppStore } from '~/store/app'

export const useProcessLevel = () => {
  const { CURRENT_RANK, RANK_CONFIG } = useUserApi()
  const { loading, load, isLoading } = useLoading()
  const { onClickAuthor, MODAL_TYPES, currentUser } = useHeader()
  const { $formatNumberWithCommas, $formatAmountUnit, $axios, $pinia } = useNuxtApp()
  const { setRecharge, setRankConfig } = useAccountStore()
  const store = useAppStore($pinia)
  const { isLogged } = storeToRefs(store)

  const level: Ref<any> = ref()
  const rankConfig: Ref<any> = ref()
  const recharge: Ref<any> = ref()

  const fetchRecharge = async (): Promise<void> => {
    if (!currentUser.value) {
      return
    }
    try {
      const { data: response } = await $axios.get(`${CURRENT_RANK}`)
      if (response && response.status === 'OK' && response.data) {
        level.value = response.data.level
        recharge.value = response.data
        setRecharge(recharge.value)
      }
    } catch (error) {
    }
  }

  const fetchRankConfig = async (): Promise<void> => {
    loading()
    try {
      const { data: response } = await $axios.get(`${RANK_CONFIG}`)
      if (response && response.status === 'OK' && response.data) {
        rankConfig.value = response.data
        setRankConfig(rankConfig.value)
      }
    } catch (error) {
      console.log('fetchRankConfig', error)
    } finally {
      load()
    }
  }

  const onNavDeposit = (): void => {
    if (currentUser.value !== null) {
      navigateTo(ACCOUNT_URLS.DEPOSIT_DA)
    } else {
      onClickAuthor(MODAL_TYPES.LOGIN, 'login')
    }
  }

  const checkCurrentLevel = (rechar: { level?: number }): string => {
    if (!currentUser.value) {
      return ''
    }
    switch (rechar?.level) {
      case 0:
        return LIST_LEVEL.VIP_1
      case 1:
        return LIST_LEVEL.VIP_2
      case 2:
        return LIST_LEVEL.VIP_3
      case 3:
        return LIST_LEVEL.VIP_4
      case 4:
        return LIST_LEVEL.VIP_5
      default:
        return ''
    }
  }

  const checkNextLevel = (rechar: { amount?: number; level?: number }): string | undefined => {
    if (!currentUser.value) {
      return
    }
    switch (rechar?.level) {
      case 0:
        return LIST_LEVEL.VIP_1
      case 1:
        return LIST_LEVEL.VIP_2
      case 2:
        return LIST_LEVEL.VIP_3
      case 3:
        return LIST_LEVEL.VIP_4
      case 4:
        return LIST_LEVEL.VIP_5
      default:
    }
  }

  const checkContentLevel = (rechar: { amount?: number; level?: number }): string => {
    if (!currentUser.value) {
      return ''
    }
    const formatNumber = $formatNumberWithCommas(Number($formatAmountUnit(rechar?.amount || 0, 1)))
    if (rechar?.level === 4) {
      return `${TEXT_CONTENT.TITLE_1} <span class="yellow">${checkCurrentLevel(rechar)}</span> ,<br/>  ${
        TEXT_CONTENT.TEXT_VIP
      }`
    } else {
      return `${TEXT_CONTENT.TITLE_1} <span class="yellow">${checkCurrentLevel(rechar)}</span> ,<br/>  ${
        TEXT_CONTENT.TITLE_2
      } <span class="green"> ${formatNumber} G</span> ${TEXT_CONTENT.TITLE_3} <span class="yellow">${checkNextLevel(
        rechar
      )}</span>`
    }
  }

  const checkPositionLevel = (rechar: { amount?: number; level?: number }): string => {
    if (!currentUser.value) {
      return ''
    }
    switch (rechar?.level) {
      case 0:
        return 'level-1'
      case 1:
        return 'level-2'
      case 2:
        return 'level-3'
      case 3:
        return 'level-4'
      case 4:
        return 'level-5'
      default:
        return ''
    }
  }

  return {
    PATH_PROMOTION_URL,
    TEXT_CONTENT,
    LIST_LEVEL_PROESS,
    fetchRecharge,
    recharge,
    level,
    fetchRankConfig,
    rankConfig,
    isLoading,
    checkCurrentLevel,
    checkContentLevel,
    checkPositionLevel,
    currentUser,
    onNavDeposit,
    isLogged
  }
}
