import { PAGE_URLS } from '~/config/page-url'

export const TEXT_CONTENT = {
  TITLE_1: 'Chúc mừng, bạn đang ở hạng',
  TITLE_2: 'nạp thêm',
  TITLE_3: 'nữa để lên hạng',
  NOTE: 'Hạng VIP mới sẽ được áp dụng và trả thưởng vào ngày đầu của tháng tiếp theo. Bấm để',
  TEXT_LINK: 'Xem chi tiết',
  LINK: PAGE_URLS.HOME,
  DEPOSIT: 'Nạp',
  USER_NEW: 'Người mới',
  DESCRIPTION: 'Hạng VIP mới sẽ được áp dụng và trả thưởng vào ngày đầu của tháng tiếp theo. Bấm để ',
  READ_MORE: 'Xem chi tiết',
  TEXT_JOIN: 'Tham gia ngay',
  TEXT_DEPOSIT: 'NẠP NGAY',
  TEXT_BET: 'CƯỢC NGAY',
  TEXT_VIP: 'tiếp tục nạp để duy trì đẳng cấp VIP',
  DESCRIPTION_VIP: 'Bạn nhận được đặc quyền hoàn trả tương ứng là:'
}

export const LIST_LEVEL = {
  VIP_1: 'VIP1',
  VIP_2: 'VIP2',
  VIP_3: 'VIP3',
  VIP_4: 'VIP4',
  VIP_5: 'VIP5'
}
export const LIST_LEVEL_PROESS = [
  {
    level: 0,
    label: 'Vip 1'
  }
]
